import { instance } from 'api/internaltools';

export const getMerchantCorridorsFromEntity = async (entityId) => {
  return instance.get(`/entity/${entityId}/corridors`);
};

export const createOtcQuote = async (payload) => {
  return instance.post('/otc/rfq', payload);
};

export const recomputeOtcQuote = async (payload) => {
  return instance.post('/otc/rfq/recompute', payload);
};

export const executeOtcTrade = async (payload) => {
  return instance.post('/otc-trade', payload);
};
