import React, { useState } from 'react';
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { updateMerchantCorridorPricing } from 'api/internaltools';
import { showError,showToast } from 'utils/notifications';


const ModifyMarkupModal = ({
  isOpen,
  onClose,
  selectedSource,
  selectedDest,
  currentPricing,
  merchantMappingId,
}) => {
  const [absMarkup, setAbsMarkup] = useState('');
  const [bpsMarkup, setBpsMarkup] = useState('');
  const [isMarkupUpdating, setIsMarkupUpdating] = useState(false);

  const toast = useToast();

  const handleModifyMarkup = async () => {
    setIsMarkupUpdating(true);
    try {
      const payload = {
        merchant_mapping_id: merchantMappingId,
        merchant_corridor_properties: {
          markup_fees: {
            ABS: parseFloat(absMarkup),
            BPS: parseFloat(bpsMarkup),
          },
        },
      };

      await updateMerchantCorridorPricing(payload);

      showToast(toast, 'Success', `Markup successfully updated.`, 'success');
      onClose();
    } catch (error) {
      showError(toast, `Error updating markup.`, error);
    } finally {
      setIsMarkupUpdating(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='sm'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Modify Markup ({selectedSource} → {selectedDest})
        </ModalHeader>
        <ModalBody>
          <VStack spacing={4} align='stretch'>
            <VStack align='stretch' spacing={2}>
              <Text color="gray">Current Markup (Without Partner Fees)</Text>
              <SimpleGrid columns={2} spacing={4}>
                <Text fontWeight="bold">ABS: {currentPricing?.MarkupFees?.ABS}</Text>
                <Text fontWeight="bold">BPS: {currentPricing?.MarkupFees?.BPS}</Text>
              </SimpleGrid>
            </VStack>

            <Divider />

            <SimpleGrid columns={2} spacing={4}>
              <FormControl>
                <FormLabel>ABS</FormLabel>
                <Input
                  type='number'
                  value={absMarkup}
                  onChange={(e) => setAbsMarkup(e.target.value)}
                  placeholder='ABS'
                />
              </FormControl>

              <FormControl>
                <FormLabel>BPS</FormLabel>
                <Input
                  type='number'
                  value={bpsMarkup}
                  onChange={(e) => setBpsMarkup(e.target.value)}
                  placeholder='BPS'
                />
              </FormControl>
            </SimpleGrid>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant='ghost' mr={3} onClick={onClose} size='sm'>
            Cancel
          </Button>
          <Button
            size='sm'
            colorScheme='blue'
            onClick={handleModifyMarkup}
            isDisabled={!absMarkup || !bpsMarkup}
            isLoading={isMarkupUpdating}
            loadingText='Updating...'
          >
            Modify Markup
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModifyMarkupModal;
