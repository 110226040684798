export const PermissionsV2 = {
  VIEW_MERCHANTS: 'VIEW_MERCHANTS',
  MANAGE_CORRIDORS: 'MANAGE_CORRIDORS',
  MANAGE_KYB: 'MANAGE_KYB',
  CREATE_ACCOUNTS: 'CREATE_ACCOUNTS',
  MANAGE_BANKING_PAYOUT: 'MANAGE_BANKING_PAYOUT',
  APPROVE_BANKING_PAYOUT: 'APPROVE_BANKING_PAYOUT',
  MANAGE_POST_ONBOARDING: 'MANAGE_POST_ONBOARDING',
  MANAGE_TECH_SUPPORT: 'MANAGE_TECH_SUPPORT',
  MANAGE_MERCHANT_CORRIDORS: 'MANAGE_MERCHANT_CORRIDORS',
  VIEW_MERCHANT_TRANSACTIONS: 'VIEW_MERCHANT_TRANSACTIONS',
  VIEW_ACCOUNTS: 'VIEW_ACCOUNTS',
  VIEW_MERCHANT_INVESTMENTS: 'VIEW_MERCHANT_INVESTMENTS',
  VIEW_KYB_DETAILS: 'VIEW_KYB_DETAILS',
  MANAGE_INTERNAL_USERS: 'MANAGE_INTERNAL_USERS',
  MANAGE_PAYMENT_POLICIES: 'MANAGE_PAYMENT_POLICIES',
  MANAGE_OTC: 'MANAGE_OTC',
};

export const PermissionMappingsV2 = {
  'merchants.view': PermissionsV2.VIEW_MERCHANTS,
  'corridors.manage': PermissionsV2.MANAGE_CORRIDORS,
  'kyb.manage': PermissionsV2.MANAGE_KYB,
  'accounts.create': PermissionsV2.CREATE_ACCOUNTS,
  'banking.payout.manage': PermissionsV2.MANAGE_BANKING_PAYOUT,
  'banking.payout.approve': PermissionsV2.APPROVE_BANKING_PAYOUT,
  'postonboarding.manage': PermissionsV2.MANAGE_POST_ONBOARDING,
  'techsupport.manage': PermissionsV2.MANAGE_TECH_SUPPORT,
  'platform.corridors.manage': PermissionsV2.MANAGE_MERCHANT_CORRIDORS,
  'accounts.transactions.view': PermissionsV2.VIEW_MERCHANT_TRANSACTIONS,
  'accounts.view': PermissionsV2.VIEW_ACCOUNTS,
  'treasury.investments.view': PermissionsV2.VIEW_MERCHANT_INVESTMENTS,
  'kyb.view': PermissionsV2.VIEW_KYB_DETAILS,
  'platform.team.manage': PermissionsV2.MANAGE_INTERNAL_USERS,
  'platform.policies.manage': PermissionsV2.MANAGE_PAYMENT_POLICIES,
  'otc.manage': PermissionsV2.MANAGE_OTC,
};

export const PERMISSION_NAMES = {
  BANKING_PAYOUT_APPROVE: 'banking.payout.approve',
};

export const ACCESS_SCOPES = {
  MERCHANTS: 'merchant',
};
