import React, { useState } from 'react';
import { IoIosRefresh } from 'react-icons/io';
import { MdOutlineEdit } from 'react-icons/md';
import { CopyIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Input,
  Select,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { getMerchantCorridorPricing } from 'api/internaltools';
import { createOtcQuote, getMerchantCorridorsFromEntity } from 'api/otc';
import { showError, showToast } from 'utils/notifications';
import DisplayOtcQuote from 'components/Otc/DisplayOtcQuote';
import ModifyMarkupModal from 'components/Otc/ModifyMarkupModal';
import RefreshedQuotes from 'components/Otc/RefreshedQuotes';

const OTCTrade = () => {
  const [entityId, setEntityId] = useState('');
  const [showTrading, setShowTrading] = useState(false);
  const [corridors, setCorridors] = useState([]);
  const [currencyMap, setCurrencyMap] = useState(new Map());
  const [sourceCurrencies, setSourceCurrencies] = useState([]);
  const [destCurrencies, setDestCurrencies] = useState([]);
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedDest, setSelectedDest] = useState('');
  const [sourceAmount, setSourceAmount] = useState('');
  const [showQuote, setShowQuote] = useState(false);
  const [quoteData, setQuoteData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCorridorLoading, setIsCorridorLoading] = useState(false);
  const [selectedCorridorId, setSelectedCorridorId] = useState('');
  const [isMarkupModalOpen, setIsMarkupModalOpen] = useState(false);
  const [currentPricing, setCurrentPricing] = useState(null);
  const [isMarkupLoading, setIsMarkupLoading] = useState(false);

  const toast = useToast();

  const resetState = () => {
    setEntityId('');
    setShowTrading(false);
    setCorridors([]);
    setCurrencyMap(new Map());
    setSourceCurrencies([]);
    setDestCurrencies([]);
    setSelectedSource('');
    setSelectedDest('');
    setSourceAmount('');
    setShowQuote(false);
    setQuoteData(null);
    setIsLoading(false);
    setIsCorridorLoading(false);
    setSelectedCorridorId('');
  };

  const handleQuoteIdCopy = (quoteId) => {
    navigator.clipboard.writeText(quoteId);
    showToast(toast, 'Quote ID copied', '', 'info');
  };

  const fetchCorridors = async () => {
    setIsCorridorLoading(true);
    try {
      const response = await getMerchantCorridorsFromEntity(entityId);

      setCorridors(response?.data?.data);
      processCorridors(response?.data?.data);
      setShowTrading(true);
    } catch (error) {
      showError(toast, 'Error fetching enabled corridors', error);
    } finally {
      setIsCorridorLoading(false);
    }
  };

  const fetchCorridorPricing = async () => {
    setIsMarkupLoading(true);
    try {
      const response = await getMerchantCorridorPricing(selectedCorridorId);

      setCurrentPricing(response?.data?.data);
      setIsMarkupModalOpen(true);
    } catch (error) {
      showError(toast, 'Error fetching corridor pricing', error);
    } finally {
      setIsMarkupLoading(false);
    }
  };

  const processCorridors = (corridorData) => {
    const map = new Map();
    const sourceCurr = new Set();

    corridorData.forEach((corridor) => {
      sourceCurr.add(corridor.source_currency_code);

      if (!map.has(corridor.source_currency_code)) {
        map.set(corridor.source_currency_code, []);
      }

      map.get(corridor.source_currency_code).push(corridor.dest_currency_code);
    });

    setCurrencyMap(map);
    setSourceCurrencies(Array.from(sourceCurr));
  };

  const handleSourceCurrencyChange = (value) => {
    setSelectedSource(value);
    setSelectedDest('');
    setSelectedCorridorId('');
    setDestCurrencies(currencyMap?.get(value) || []);
    setShowQuote(false);
    setQuoteData(null);
  };

  const handleDestCurrencyChange = (value) => {
    setSelectedDest(value);
    if (!value) {
      setSelectedSource('');
      setDestCurrencies([]);
    }
    setSelectedCorridorId('');
    setShowQuote(false);
    setQuoteData(null);

    if (value && selectedSource) {
      const selectedCorridor = corridors?.find(
        (corridor) => corridor?.source_currency_code === selectedSource && corridor?.dest_currency_code === value
      );

      setSelectedCorridorId(selectedCorridor?.id || '');
    }
  };

  const handleRequestQuote = async () => {
    if (sourceAmount && selectedCorridorId) {
      setIsLoading(true);
      try {
        const payload = {
          entity_id: entityId,
          corridor_id: selectedCorridorId,
          source_amount: parseFloat(sourceAmount),
        };

        const quoteResponse = await createOtcQuote(payload);

        setQuoteData(quoteResponse?.data?.data);
        setShowQuote(true);
      } catch (error) {
        showError(toast, 'Error requesting quote', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Container maxW='container.xl' py={8}>
      <HStack justify='space-between' mb={3}>
        <Heading as='h1' size='md'>
          OTC Trading
        </Heading>
        {showTrading && (
          <Button variant='outline' colorScheme='black' size='sm' onClick={resetState} leftIcon={<IoIosRefresh />}>
            Start Over
          </Button>
        )}
      </HStack>
      {!showTrading ? (
        <VStack spacing={4} align='stretch'>
          <Grid templateColumns='repeat(2, 1fr)' gap={4}>
            <GridItem>
              <FormControl>
                <FormLabel>Entity ID</FormLabel>
                <Input
                  value={entityId}
                  onChange={(e) => setEntityId(e.target.value)}
                  placeholder='Enter Entity ID'
                  isDisabled={isCorridorLoading}
                  onKeyDown={(e) => e.key === 'Enter' && entityId && fetchCorridors()}
                />
              </FormControl>
            </GridItem>
            <GridItem alignSelf='end'>
              <Button
                colorScheme='blue'
                onClick={fetchCorridors}
                isLoading={isCorridorLoading}
                isDisabled={!entityId || isCorridorLoading}
                w='fit-content'
              >
                Proceed
              </Button>
            </GridItem>
          </Grid>
        </VStack>
      ) : (
        <VStack spacing={8} align='stretch'>
          <Grid templateColumns='repeat(2, 1fr)' gap={8}>
            <GridItem>
              <Heading as='h1' size='sm' mb={2}>
                Request Quote
              </Heading>
              <VStack spacing={6} align='stretch' borderWidth='1px' borderRadius='lg' p={6}>
                <Box>
                  <Text mb={2} fontWeight='bold'>
                    Sell
                  </Text>
                  <HStack>
                    <Input value={sourceAmount} onChange={(e) => setSourceAmount(e.target.value)} placeholder='0.00' />
                    <Select
                      value={selectedSource}
                      onChange={(e) => handleSourceCurrencyChange(e.target.value)}
                      placeholder='Select currency'
                    >
                      {sourceCurrencies?.map((currency) => (
                        <option key={currency} value={currency}>
                          {currency}
                        </option>
                      ))}
                    </Select>
                  </HStack>
                </Box>

                <Box>
                  <Text mb={2} fontWeight='bold'>
                    Buy
                  </Text>
                  <HStack>
                    <Input
                      isDisabled
                      value={quoteData?.receiving_amount || ''}
                      placeholder='0.00'
                      bg='gray.100'
                      _disabled={{
                        opacity: 0.8,
                        cursor: 'not-allowed',
                        bg: 'gray.100',
                        borderColor: 'gray.300',
                      }}
                    />
                    <Select
                      value={selectedDest}
                      onChange={(e) => handleDestCurrencyChange(e.target.value)}
                      placeholder='Select currency'
                    >
                      {destCurrencies?.map((currency) => (
                        <option key={currency} value={currency}>
                          {currency}
                        </option>
                      ))}
                    </Select>
                  </HStack>
                </Box>

                {selectedSource && selectedDest && (
                  <Button
                    size='sm'
                    variant='outline'
                    onClick={fetchCorridorPricing}
                    isLoading={isMarkupLoading}
                    leftIcon={<MdOutlineEdit />}
                  >
                    Modify Markup
                  </Button>
                )}

                <Button
                  colorScheme='blue'
                  onClick={handleRequestQuote}
                  isDisabled={!sourceAmount || !selectedCorridorId || isLoading}
                  isLoading={isLoading}
                  loadingText='Requesting quote...'
                >
                  Request Quote
                </Button>

                {isMarkupModalOpen && (
                  <ModifyMarkupModal
                    isOpen={isMarkupModalOpen}
                    onClose={() => setIsMarkupModalOpen(false)}
                    selectedSource={selectedSource}
                    selectedDest={selectedDest}
                    currentPricing={currentPricing}
                    merchantMappingId={selectedCorridorId}
                    merchantId={corridors?.length > 0 ? corridors[0]?.parent_id : ''}
                  />
                )}

                {showQuote && quoteData?.id && (
                  <Alert status='info' variant='left-accent'>
                    <VStack align='stretch' width='full'>
                      <HStack justify='space-between'>
                        <HStack>
                          <AlertIcon />
                          <AlertTitle>Quote ID</AlertTitle>
                        </HStack>
                        <IconButton
                          size='sm'
                          icon={<CopyIcon />}
                          aria-label='Copy quote ID'
                          onClick={() => handleQuoteIdCopy(quoteData?.id)}
                          variant='ghost'
                          colorScheme='blue'
                          _hover={{
                            bg: 'blue.50',
                          }}
                        />
                      </HStack>
                      <AlertDescription>
                        <HStack spacing={2}>
                          <Text fontWeight='medium'>{quoteData?.id}</Text>
                        </HStack>
                        <Text fontSize='sm' mt={2} color='gray.600'>
                          Please save the quote ID for future reference. You won&apos;t see it again.
                        </Text>
                      </AlertDescription>
                    </VStack>
                  </Alert>
                )}
              </VStack>
            </GridItem>

            {(showQuote || isLoading) && (
              <GridItem>
                <Heading size='sm' mb={2}>
                  Current Quote
                </Heading>
                <DisplayOtcQuote
                  isLoading={isLoading}
                  quoteData={quoteData}
                  selectedSource={selectedSource}
                  selectedDest={selectedDest}
                />
              </GridItem>
            )}
          </Grid>
          <RefreshedQuotes entityId={entityId} />
        </VStack>
      )}
    </Container>
  );
};

export default OTCTrade;
