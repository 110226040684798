import React, { useState } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Input,
  Switch,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { executeOtcTrade } from 'api/otc';
import { showError, showToast } from 'utils/notifications';

const ExecuteTradeDrawer = ({ isOpen, onClose, entityId, recomputedQuote }) => {
  const [sourceAccountId, setSourceAccountId] = useState('');
  const [destAccountId, setDestAccountId] = useState('');
  const [isExecuteTradeEnabled, setIsExecuteTradeEnabled] = useState(true);
  const [isExecuting, setIsExecuting] = useState(false);
  const [receivingAmount, setReceivingAmount] = useState('');

  const toast = useToast();

  const resetForm = () => {
    setSourceAccountId('');
    setDestAccountId('');
    setIsExecuteTradeEnabled(true);
    setReceivingAmount('');
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleExecuteTrade = async () => {
    if (!sourceAccountId || !destAccountId || !recomputedQuote?.recomputed_quote?.id || !entityId) return;

    setIsExecuting(true);
    try {
      const payload = {
        entity_id: entityId,
        source_account_id: sourceAccountId,
        dest_account_id: destAccountId,
        quote_id: recomputedQuote?.recomputed_quote?.id,
        is_execute_otc_trade: isExecuteTradeEnabled,
        updated_receiving_amount: parseFloat(receivingAmount),
      };

      await executeOtcTrade(payload);

      showToast(toast, 'Trade Executed Successfully', '', 'success');

      handleClose();
    } catch (error) {
      showError(toast, 'Error executing trade', error);
    } finally {
      setIsExecuting(false);
    }
  };

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={handleClose} size='md'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Trade Details</DrawerHeader>

        <DrawerBody>
          <VStack spacing={6}>
            <FormControl>
              <FormLabel>Updated Receiving Amount (Optional)</FormLabel>
              <Input
                type='number'
                value={receivingAmount}
                onChange={(e) => setReceivingAmount(e.target.value)}
                placeholder='Enter receiving amount'
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Source Account ID</FormLabel>
              <Input
                value={sourceAccountId}
                onChange={(e) => setSourceAccountId(e.target.value)}
                placeholder='Enter source account ID'
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Destination Account ID</FormLabel>
              <Input
                value={destAccountId}
                onChange={(e) => setDestAccountId(e.target.value)}
                placeholder='Enter destination account ID'
              />
            </FormControl>

            <FormControl display='flex' alignItems='center'>
              <FormLabel mb='0'>Execute the trade?</FormLabel>
              <Switch
                isChecked={isExecuteTradeEnabled}
                onChange={(e) => setIsExecuteTradeEnabled(e.target.checked)}
                defaultChecked
              />
            </FormControl>
          </VStack>
        </DrawerBody>

        <DrawerFooter>
          <Button variant='outline' mr={3} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            colorScheme='blue'
            onClick={handleExecuteTrade}
            isLoading={isExecuting}
            loadingText='Executing...'
            isDisabled={!sourceAccountId || !destAccountId}
          >
            Execute Trade
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ExecuteTradeDrawer;
