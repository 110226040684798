import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { recomputeOtcQuote } from 'api/otc';
import { showError } from 'utils/notifications';
import DisplayOtcQuote from 'components/Otc/DisplayOtcQuote';
import ExecuteTradeDrawer from 'components/Otc/ExecuteTradeDrawer';

const RefreshedQuotes = ({ entityId }) => {
  const [executeQuoteId, setExecuteQuoteId] = useState('');
  const [isRecomputeLoading, setIsRecomputeLoading] = useState(false);
  const [comparisonQuotes, setComparisonQuotes] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const handleRecomputeQuote = async () => {
    if (!executeQuoteId || !entityId) return;

    setIsRecomputeLoading(true);
    try {
      const payload = {
        entity_id: entityId,
        quote_id: executeQuoteId,
      };

      const response = await recomputeOtcQuote(payload);

      setComparisonQuotes(response?.data?.data);
    } catch (error) {
      setComparisonQuotes(null);
      showError(toast, 'Error recomputing quote', error);
    } finally {
      setIsRecomputeLoading(false);
    }
  };

  return (
    <VStack spacing={6} align='stretch' borderWidth='1px' borderRadius='lg' p={6}>
      <Heading size='sm' as='h1'>
        Execute OTC Trade
      </Heading>
      <HStack spacing={4}>
        <FormControl>
          <FormLabel>Quote ID</FormLabel>
          <Input
            value={executeQuoteId}
            onChange={(e) => setExecuteQuoteId(e.target.value)}
            placeholder='Enter Quote ID'
            onKeyDown={(e) => e.key === 'Enter' && handleRecomputeQuote()}
            isDisabled={isRecomputeLoading}
          />
        </FormControl>
        <Button
          colorScheme='black'
          variant='outline'
          onClick={handleRecomputeQuote}
          isLoading={isRecomputeLoading}
          loadingText='Refreshing...'
          alignSelf='end'
          px={6}
        >
          Show Refreshed Quote
        </Button>
      </HStack>

      {comparisonQuotes && (
        <Grid templateColumns='repeat(2, 1fr)' gap={4} mt={4}>
          <GridItem>
            <Box borderWidth='1px' borderRadius='lg' p={4}>
              <Heading size='sm' mb={4}>
                Current Quote
              </Heading>
              <DisplayOtcQuote
                isLoading={isRecomputeLoading}
                quoteData={comparisonQuotes?.quote}
                selectedSource={comparisonQuotes?.source_currency_code}
                selectedDest={comparisonQuotes?.destination_currency_code}
              />
            </Box>
          </GridItem>
          <GridItem>
            <Box borderWidth='1px' borderRadius='lg' p={4}>
              <Heading size='sm' mb={4}>
                Refreshed Quote
              </Heading>
              <DisplayOtcQuote
                isLoading={isRecomputeLoading}
                quoteData={comparisonQuotes?.recomputed_quote}
                selectedSource={comparisonQuotes?.source_currency_code}
                selectedDest={comparisonQuotes?.destination_currency_code}
              />
            </Box>
          </GridItem>

          {!isRecomputeLoading && (
            <Flex>
              <Button colorScheme='blue' onClick={onOpen} alignSelf='center' size='lg'>
                Proceed with Trade
              </Button>
            </Flex>
          )}

          {isOpen && (
            <ExecuteTradeDrawer
              isOpen={isOpen}
              onClose={onClose}
              entityId={entityId}
              recomputedQuote={comparisonQuotes}
            />
          )}
        </Grid>
      )}
    </VStack>
  );
};

export default RefreshedQuotes;
