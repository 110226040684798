import React from 'react';
import { Box, Divider, Skeleton, Stat, StatLabel, StatNumber, Text, VStack } from '@chakra-ui/react';

const DisplayOtcQuote = ({ isLoading, quoteData, selectedSource, selectedDest }) => {
  const labelValueMap = [
    { label: 'Source Amount', value: `${quoteData?.source_amount} ${selectedSource}` },
    { label: 'Total Fees', value: `${quoteData?.total_fees} ${quoteData?.total_fees_currency}` },
    { label: 'Gas Fee', value: `${quoteData?.gas_fees} ${quoteData?.total_fees_currency}` },
    { label: 'Platform Fee', value: `${quoteData?.platform_fees} ${quoteData?.total_fees_currency}` },
    { label: 'Amount to be converted', value: `${quoteData?.converted_amount} ${selectedSource}` },
    { label: 'Total quote amount', value: `${quoteData?.receiving_amount} ${selectedDest}` },
  ];

  return (
    <Box borderWidth='1px' borderRadius='lg' p={6} bg='gray.100'>
      <VStack spacing={4} align='stretch'>
        {isLoading ? (
          <>
            <Skeleton height='60px' />
            <Skeleton height='24px' />
            <Skeleton height='24px' />
            <Skeleton height='24px' />
            <Divider variant='dashed' borderColor='gray.400' />
            <Skeleton height='24px' />
            <Skeleton height='24px' />
          </>
        ) : (
          <>
            <Stat>
              <StatLabel>Exchange Rate</StatLabel>
              <StatNumber>
                1 {selectedSource} = {quoteData?.exchange_rate} {selectedDest}
              </StatNumber>
            </Stat>

            <Divider variant='dashed' borderColor='gray.400' />

            <VStack spacing={4} align='start'>
              {labelValueMap?.map((item, index) => (
                <Box key={index}>
                  <Text>{item?.label}</Text>
                  <Text fontWeight='bold'>{item?.value}</Text>
                </Box>
              ))}
            </VStack>

            <Text fontSize='sm' color='gray.500'>
              Quote expires at {quoteData?.expired_at ? new Date(quoteData.expired_at).toLocaleTimeString() : ''}
            </Text>
          </>
        )}
      </VStack>
    </Box>
  );
};

export default DisplayOtcQuote;
