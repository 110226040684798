import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import {
  ACCOUNTS_ROUTE,
  CORRIDORS_ROUTE,
  KYB_SUBMISSION_ROUTE,
  MERCHANTS_ROUTE,
  OTC_ROUTE,
  PARTNER_VISIBILITY_ROUTE,
  PAYMENTS_ROUTE,
  POST_ONBOARDING_ROUTE,
  TECH_SUPPORT_REQUEST,
  TRANSACTIONS_ROUTE,
} from 'constants/routes';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import MetricsButton from 'components/MetricsButton/MetricsButton';

export default function Home() {
  return (
    <Flex flexDirection='column' pt='30px'>
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={6}>
        <Link to={MERCHANTS_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Merchants
                </Text>
                <Text color='gray.400'>Check all merchants and their details.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={CORRIDORS_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Corridors
                </Text>
                <Text color='gray.400'>Check all corridors and edit corridor fees.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={ACCOUNTS_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Add Account
                </Text>
                <Text color='gray.400'>Create new accounts for entities on Partners.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={TRANSACTIONS_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Transactions
                </Text>
                <Text color='gray.400'>Check merchant transactions and change their status.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={PAYMENTS_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Payments
                </Text>
                <Text color='gray.400'>Check pending transactions and approve or decline them.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={OTC_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Place an OTC trade
                </Text>
                <Text color='gray.400'>
                  Create OTC quote and execute the trade once the customer accepts.
                </Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={PARTNER_VISIBILITY_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Partner Visibility
                </Text>
                <Text color='gray.400'>Check counterparty balances and risks.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={KYB_SUBMISSION_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Submit Know-Your-Business (KYB)
                </Text>
                <Text color='gray.400'>Upload company documents and submit KYB to Partners.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={POST_ONBOARDING_ROUTE}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Post-Onboard Entity
                </Text>
                <Text color='gray.400'>Create accounts and corridors for the onboarded entities.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>

        <Link to={TECH_SUPPORT_REQUEST}>
          <Card minHeight='250px' _hover={{ bg: 'gray.100' }} style={{ borderWidth: '1px' }}>
            <CardBody w='100%'>
              <Flex direction='column'>
                <Text fontSize='xl' mb={2} fontWeight='bold'>
                  Request Tech Support
                </Text>
                <Text color='gray.400'>Tech Turmoil? Request Tech Support Login to verify customer data.</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>
      </SimpleGrid>

      <MetricsButton />
    </Flex>
  );
}
